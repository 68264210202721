<template>
  <div class="main-view-padding has-header">
      <div class="header main" v-header-position-lock>
          <div class="align-left">
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
          </div>
      </div>
    <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
      Mögliche Duplikate
    </div>




    <div class="scrollbar-container">
      <div v-for="employee in employees" v-bind:key="'e'+employee.id">
        <table class="table">
          <tr>
            <th style="width:30%">Bild</th>
            <th style="width:10%">Übereinstimmung</th>
            <th style="width:20%">Vorname</th>
            <th style="width:20%">Nachname</th>
            <th style="width:10%">Standort ID</th>
            <th style="width:10%">Mitarbeiter ID</th>
          </tr>
        </table>
        <table class="table">
          <tr>
            <td style="width:30%"><img style="width:110px;height:110px;" v-bind:src="employee.employeePicture_server + '/api/file?id=' + employee.employeePicture_fileId + '&accessToken=' + employee.employeePicture_accessToken"/></td>
            <td style="width:10%">Vergleichs-Objekt</td>
            <td style="width:20%">{{employee.firstName}}</td>
            <td style="width:20%">{{employee.lastName}}</td>
            <td>Store MD-<strong>ID</strong> : {{employee.defaultStoreId}}</td>
            <td style="width:10%">{{employee.id}}</td>
          </tr>
          <tr v-for="match in employee.matches" v-bind:key="'em'+employee.id+'w'+match.id">
            <td><img style="width:110px;height:110px;" v-bind:src="match.employeePicture_server + '/api/file?id=' + match.employeePicture_fileId + '&accessToken=' + match.employeePicture_accessToken"/></td>
            <td>{{match.totalPercent}} %</td>
            <td>{{match.firstName}}</td>
            <td>{{match.lastName}}</td>
            <td>Store MD-<strong>ID</strong> : {{match.defaultStoreId}}</td>
            <td>{{match.id}}</td>
          </tr>
        </table>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import button from '../uicomponents/button.vue';
export default {
 components: { button },
  name: 'employeeDuplicateCleanup',
  data () {
    return {
      employees: [],
    }
  },
  created: function () {
    //console.log("CREATED");
  },
  mounted: function () {
    if(this.$store.state.jwt != undefined && this.$store.state.companyId != undefined){
        this.refresh();
    }
  },
  computed: {
        ...mapGetters(['companyId', 'isSuperUser']),
        dateDisplay: function() {
          return this.$helpers.getMomentTZ().format('YYYY-MM-DD');
        },
          headerButtons() {
              let view = this;
              let state= { buttons: [] };
              /*
              state.buttons.push({ type: "block", icon: "fa-eye", click: () => { }, tooltip: 'VIEW' });
              state.buttons.push({ type: "block", icon: "fa-cloud-download-alt", click: () => { view.loadExternalUsersFromKundencenter(); }, tooltip: 'SYNC' });
              */
              return state;
          },
        timeZoneList() {
            let result = [];
            /*
            this.timeZones.forEach(i => {
                let item = {label: i, value: i};
                result.push(item);
            })
            */
            return result;
        },
  },
  watch: {
    companyId: function(val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function(force = false){
        let view = this;
        view.loading = true;
        view.loadEmployees();
    },

    loadEmployees: function(force = false){
      let view = this;
      let url = '/api/employees/cleanup';
        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/employees/cleanup');
      
      this.axios({
        method: 'get',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
      }).then(response => {
        view.employees = response.data;
      }).catch(function (error) {
        console.log(error);
      })
    }
  },
}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>

.xColumn3{
  width: 400px; 
  text-align:left; 
  float:left; 
  margin-left:25px; 
  margin-right:25px;
  overflow-y: auto;
  max-height: 800px;
}

</style>
